/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
[dir='ltr'] .notification {
  padding: 10px 10px 10px 58px;
}

[dir='ltr'] .top-1 {
  top: 5%;
}

[dir='ltr'] .custom-mx-1 {
  margin: 0 8px;
}

[dir='ltr'] .ml-auto {
  margin-left: auto;
}

[dir='ltr'] .ml-1 {
  margin-left: 8px;
}

[dir='ltr'] .mr-1 {
  margin-right: 8px;
}

[dir='ltr'] .mr-2 {
  margin-right: 8px;
}

[dir='ltr'] .mr-3 {
  margin-right: 16px;
}

[dir='ltr'] .mr-4 {
  margin-right: 24px;
}

[dir='ltr'] .custom-list-item {
  padding: 0;
  height: 43px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.mr-1 {
  margin-right: 8px;
}

//Material ui label styles
[dir='ltr'] .dark-layout .labelStyles,
[dir='ltr'] .dark-layout .MuiFormLabel-root,
[dir='ltr'] .dark-layout .MuiTypography-root,
[dir='ltr'] .dark-layout .MuiInputBase-input,
[dir='ltr'] .dark-layout .MuiPaginationItem-root,
[dir='ltr'] .dark-layout .MuiPaper-root ul li {
  color: #b4b7bd;
}

[dir='ltr'] .dark-layout .MuiPaper-root {
  background-color: #283046;
}

// [dir='ltr'] .dark-layout .MuiTextField-root,
[dir='ltr'] .dark-layout .MuiInputBase-root {
  border: 1px solid #404656;
  border-radius: 6px;
}
[dir='ltr'] .MuiInputBase-root {
  border: 1px solid #d8d6de;
}
[dir='ltr'] .dark-layout .MuiSelect-select:focus {
  background-color: transparent !important;
}
[dir='ltr'] .dark-layout .MuiPaper-root ul {
  padding-left: 0;
}
//Editor styles

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

[dir='ltr'] .mini-table {
  max-height: 302.5px;
  overflow-x: auto;
}

[dir='ltr'] .date-wrapper {
  position: relative;
}
[dir='ltr'] .date-wrapper .date-clear-button {
  position: absolute;
  top: 8px;
  right: 8px;
}
[dir='ltr'] .numInputWrapper span {
  display: unset !important;
}
[dir='ltr'] .dark-layout .numInputWrapper .arrowUp::after {
  border-bottom-color: #fff !important;
}
[dir='ltr'] .dark-layout .numInputWrapper .arrowDown::after {
  border-top-color: #fff !important;
}
[dir='ltr'] .dark-layout .rst__moveHandle,
[dir='ltr'] .dark-layout .rst__rowContents {
  background-color: #283046 !important;
}
[dir='ltr'] .dark-layout .rst__lineFullVertical::after,
[dir='ltr'] .dark-layout .rst__lineHalfHorizontalRight::before,
[dir='ltr'] .dark-layout .rst__lineHalfHorizontalRight::after {
  background-color: #fff !important;
}

[dir='ltr'] .cCrguf {
  min-height: 540px;
}
[dir='ltr'] .dark-layout .cCrguf {
  background-color: #283046;
}

.tox-collection__group {
  div[title='Circle'] {
    .tox-collection__item-icon {
      display: list-item;
      list-style: none;
      width: 48px;
      height: 48px;
      background-image: url(../images/pink-dot.svg);
      background-size: 30px 30px;
      background-repeat: no-repeat;
      background-position: center center;
      svg {
        display: none;
      }
    }
  }
}
